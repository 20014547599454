<template>
  <span>
    <span>[</span>

    <div
      v-for="(item, x) in data"
      :key="`${x}`"
      class="q-ml-md"
    >
      <span v-if="isNotObject(item)">
        {{ JSON.stringify(item) }}
      </span>

      <array-code
        v-else-if="Array.isArray(item)"
        :data="item"
      />

      <object-code
        v-else
        :data="item"
      />

      <span v-if="x + 1 < data.length">,</span>
    </div>

    <span>]</span>
  </span>
</template>

<script>
export default {
  name: 'ArrayCode',
  props: {
    data: {
      default () {
        return []
      }
    }
  },
  methods: {
    isNotObject (data) {
      return !data || typeof data !== 'object'
    }
  }
}
</script>
